<template>
    <div>
        <h2 class="text-secondary">{{ theme.pageTitle }}</h2>
    </div>

    <div class="row mb-5">
        <div class="col-12 col-lg-7 order-1 order-lg-0">
            <div v-html="theme.content"/>
        </div>
        <div class="col-12 col-lg-5 order-0 order-lg-1">
            <Video v-if="theme.videoEmbed" :embed="theme.videoEmbed" />
        </div>
    </div>

    <DocumentCards :documents="knowledgebaseItems" :show_title="false" />
</template>

<script>
    import api from '@/data/api'
    import DocumentCards from "@/components/DocumentCards";
    import Video from '@/components/Video.vue';

    export default {
        name: 'Kennisbank Thema',
        components: {
            DocumentCards,
            Video
        },
        data() {
            return {
                pageTitle: 'Kennisbank',
                theme: {},
                knowledgebaseItems: []
            }
        },
        created() {
            this.theme = this.$store.getters.getThemeBySlug(this.$route.params.thema);

            api.fetchKnowledgeBaseItemsByTheme(this.$route.params.thema).then(response => {
                this.knowledgebaseItems = response;
            });
        },
        mounted() {
            const breadcrumbs = [
                {
                    'label': 'Home',
                    'path': '/'
                },
                {
                    'label': 'Kennisbank',
                    'path': '/kennisbank/'
                },
                {
                    'label': this.theme.pageTitle,
                },
            ];

            this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
        }
    }
</script>
